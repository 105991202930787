<cx-header>
  <cx-header-title>
    {{ 'ORDER_OVERVIEW.TITLE' | translate }}
  </cx-header-title>
  <cx-header-quickbar>
    <hpm-user-profile></hpm-user-profile>
  </cx-header-quickbar>
</cx-header>
<div class="container">
    <hpm-tab-navigation></hpm-tab-navigation>
    <hpm-order-table></hpm-order-table>
</div>
<cx-floating-button [text]="'ORDER_OVERVIEW.ADD_NEW_ORDER' | translate" routerLink="/order/new"></cx-floating-button>
