<div class="table-wrapper row">

  <hpm-search-bar class="col-12"
                  (searchValue)="addSearchFilter($event)"
                  placeholder="ORDER_OVERVIEW.SEARCH_PLACEHOLDER"
                  [autocompleteOptions]="autocompleteOptions"></hpm-search-bar>

  <cx-table-container [tableLoading]="tableLoading">

    <cx-table-with-pagination
      [initialTableColumnsOrder]="defaultOrderTableColumns"
      [data]="shownOrders"
      [sorting]="tableSettings.sort"
      [stickyHeader]="false"
      [showTableBorder]="false"
      [showTableRowHover]="true"
      [minLengthForPaginator]="0"
      [paginatorTotalRecordsCount]="totalElementCount"
      [rowType]="rowType.TWO_ROWS"
      [stickyHeaderTopValue]="'125px'"
      (sortChange)="onSortingDirectionChanged($event)"
      [paginatorPageSize]="tableSettings.pageSize"
      [paginatorPageIndex]="tableSettings.pageNumber"
      (paginatorChange)="onPaginatorChange($event)"
      (rowClicked)="navigateToDetailPage($event)"
      matSort>

      <ng-container matColumnDef="{{columnDef.LAST_CHANGE_DATE}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.LAST_CHANGE_DATE' | translate }}
          <cx-table-filter
            columnDef="{{columnDef.LAST_CHANGE_DATE}}"
            filterHeaderText="{{'ORDER_OVERVIEW.LAST_CHANGE_DATE_FILTER' | translate}}"
            [filterType]="tableFilterType.DATEPICKER"
            [hasSelectedCalendarDate]="selectedLastChangedDateRange.start !== null && selectedLastChangedDateRange.end !== null"
          >
            <mat-calendar
              [selected]="selectedLastChangedDateRange"
              (selectedChange)="onSelectedCalenderDate(columnFilterEnum.LAST_CHANGE_DATE, $event)"
            ></mat-calendar>
          </cx-table-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="table-cell">

          {{ row.lastChangeDate | date: 'dd.MM.yyyy' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="{{columnDef.CUSTOMER}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.CUSTOMER' | translate }}
          <cx-table-filter
            columnDef="{{columnDef.CUSTOMER}}"
            filterHeaderText=" {{'ORDER_OVERVIEW.CUSTOMER_FILTER' | translate}}"
            [filterType]="tableFilterType.LIST"
            [filterOptions]="this.filterValueMap?.[columnDef.CUSTOMER] || []"
            (filterSelectionChange)="onFilterSelectionChange(columnFilterEnum.CUSTOMER, $event)"
          ></cx-table-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="table-cell">
          {{ row.customer }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="{{columnDef.SPECIALTY_FIELD}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.SPECIALTY_FIELD' | translate }}
          <cx-table-filter
            columnDef="{{columnDef.SPECIALTY_FIELD}}"
            filterHeaderText=" {{'ORDER_OVERVIEW.SPECIALTY_FIELD_FILTER' | translate}}"
            [filterType]="tableFilterType.LIST"
            [filterOptions]="this.filterValueMap?.[columnDef.SPECIALTY_FIELD] || []"
            (filterSelectionChange)="onFilterSelectionChange(columnFilterEnum.SPECIALTY_FIELD, $event)">
          </cx-table-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="table-cell"
                  [matTooltip]="row.specialtyField?.length > 3 ? row.specialtyField?.join(', ') : ''">
          {{ row.specialtyField?.join(', ') }}
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="{{columnDef.CUSTOMER_TRANSACTION_NUMBER}}">
        <mat-header-cell [style.display]="userCanSeeCustomerTransactionNumber?'':'none'" *matHeaderCellDef
                         mat-sort-header>
          {{ 'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.CUSTOMER_TRANSACTION_NUMBER' | translate }}
          <cx-table-filter
            columnDef="{{columnDef.CUSTOMER_TRANSACTION_NUMBER}}"
            filterHeaderText=" {{'ORDER_OVERVIEW.CUSTOMER_TRANSACTION_NUMBER_FILTER' | translate}}"
            [filterType]="tableFilterType.LIST"
            [filterOptions]="this.filterValueMap?.[columnDef.CUSTOMER_TRANSACTION_NUMBER] || []"
            (filterSelectionChange)="onFilterSelectionChange(columnFilterEnum.CUSTOMER_TRANSACTION_NUMBER, $event)"
          ></cx-table-filter>
        </mat-header-cell>
        <mat-cell [style.display]="userCanSeeCustomerTransactionNumber?'':'none'" *matCellDef="let row" class="table-cell">
          {{ row.customerTransactionNumber }}
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="{{columnDef.ASSIGNED_TO}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.ASSIGNED_TO' | translate }}
          <cx-table-filter
            columnDef="{{columnDef.ASSIGNED_TO}}"
            filterHeaderText="{{'ORDER_OVERVIEW.ASSIGNED_TO_FILTER' | translate}}"
            [filterType]="tableFilterType.LIST"
            [filterOptions]="this.filterValueMap?.[columnDef.ASSIGNED_TO] || []"
            (filterSelectionChange)="onFilterSelectionChange(columnFilterEnum.ASSIGNED_TO, $event)">
          </cx-table-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="table-cell">
          {{ row.assignedTo }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="{{columnDef.STATUS}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.STATUS' | translate }}
          <cx-table-filter
            columnDef="{{columnDef.STATUS}}"
            filterHeaderText="{{'ORDER_OVERVIEW.STATUS_FILTER' | translate}}"
            [filterType]="tableFilterType.LIST"
            [filterOptions]="this.filterValueMap?.[columnDef.STATUS] || []"
            (filterSelectionChange)="onFilterSelectionChange(columnFilterEnum.STATUS, $event)">
          </cx-table-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="table-cell">
          <div [ngClass]="row.status" class="status-column-wrapper">
            {{ 'ORDER_OVERVIEW.ORDER_STATUS.' + row.status | translate }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="{{columnDef.PRIORITY}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.PRIORITY' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="table-cell">
          <div *ngIf="row.priority" [ngClass]="'priority'">
            {{ 'ORDER_OVERVIEW.PRIORITY_HIGH' | translate }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="{{columnDef.CREATED_ON}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.CREATED_ON' | translate }}
          <cx-table-filter
            columnDef="{{columnDef.CREATED_ON}}"
            filterHeaderText="{{'ORDER_OVERVIEW.CREATED_ON_FILTER' | translate}}"
            [filterType]="tableFilterType.DATEPICKER"
            [hasSelectedCalendarDate]="selectedCreatedOnDateRange.start !== null && selectedCreatedOnDateRange.end !== null">
            <mat-calendar
              [selected]="selectedCreatedOnDateRange"
              (selectedChange)="onSelectedCalenderDate(columnFilterEnum.CREATED_ON, $event)">
            </mat-calendar>
          </cx-table-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="table-cell">
          {{ row.createdOn | date: 'dd.MM.yyyy' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="{{columnDef.ADDRESS}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.ADDRESS' | translate }}
          <cx-table-filter
            columnDef="{{columnDef.ADDRESS}}"
            filterHeaderText="{{'ORDER_OVERVIEW.ADDRESS_FILTER' | translate}}"
            [filterType]="tableFilterType.LIST"
            [filterOptions]="this.filterValueMap?.[columnDef.ADDRESS] || []"
            (filterSelectionChange)="onFilterSelectionChange(columnFilterEnum.ADDRESS, $event)">
          </cx-table-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="table-cell">
          {{ row.address }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="{{columnDef.REASON}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.REASON' | translate }}
          <cx-table-filter
            columnDef="{{columnDef.REASON}}"
            filterHeaderText="{{'ORDER_OVERVIEW.REASON_FILTER' | translate}}"
            [filterType]="tableFilterType.LIST"
            [filterOptions]="this.filterValueMap?.[columnDef.REASON] || []"
            (filterSelectionChange)="onFilterSelectionChange(columnFilterEnum.REASON, $event)">
          </cx-table-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="table-cell">
          {{ row.reason }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="{{columnDef.CREATED_BY}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.CREATED_BY' | translate }}
          <cx-table-filter
            columnDef="{{columnDef.CREATED_BY}}"
            filterHeaderText="{{'ORDER_OVERVIEW.CREATED_BY_FILTER' | translate}}"
            [filterType]="tableFilterType.LIST"
            [filterOptions]="this.filterValueMap?.[columnDef.CREATED_BY] || []"
            (filterSelectionChange)="onFilterSelectionChange(columnFilterEnum.CREATED_BY, $event)">
          </cx-table-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="table-cell">
          {{ row.createdBy }}
        </mat-cell>
      </ng-container>

      <cx-table-optional-column
        name="optionalColumns"
        [enableAllColumnReorder]="true"
        [defaultColumnOrder]="defaultOrderTableColumns"
        (visibleColumnsChange)="saveVisibleColumnsToStorage($event)">
      </cx-table-optional-column>

    </cx-table-with-pagination>

    <ng-container>
      <ng-container
        cxDropdownColumn="{{columnDef.LAST_CHANGE_DATE}}"
        [cxOptionalColumn]="true"
        cxDropdownColumnTitle="{{'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.LAST_CHANGE_DATE' | translate}}"/>
      <ng-container
        cxDropdownColumn="{{columnDef.CUSTOMER}}"
        [cxOptionalColumn]="true"
        cxDropdownColumnTitle="{{'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.CUSTOMER' | translate}}"/>
      <ng-container
        cxDropdownColumn="{{columnDef.SPECIALTY_FIELD}}"
        [cxOptionalColumn]="true"
        cxDropdownColumnTitle="{{'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.SPECIALTY_FIELD' | translate}}"/>
      <ng-container
        cxDropdownColumn="{{columnDef.CUSTOMER_TRANSACTION_NUMBER}}"
        [cxOptionalColumn]="true"
        cxDropdownColumnTitle="{{'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.CUSTOMER_TRANSACTION_NUMBER' | translate}}"/>
      <ng-container
        cxDropdownColumn="{{columnDef.ASSIGNED_TO}}"
        [cxOptionalColumn]="true"
        cxDropdownColumnTitle="{{'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.ASSIGNED_TO' | translate}}"/>
      <ng-container
        cxDropdownColumn="{{columnDef.STATUS}}"
        [cxOptionalColumn]="true"
        cxDropdownColumnTitle="{{'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.STATUS' | translate}}"/>
      <ng-container
        cxDropdownColumn="{{columnDef.PRIORITY}}"
        [cxOptionalColumn]="true"
        cxDropdownColumnTitle="{{'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.PRIORITY' | translate}}"/>
      <ng-container
        cxDropdownColumn="{{columnDef.ADDRESS}}"
        [cxOptionalColumn]="true"
        cxDropdownColumnTitle="{{'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.ADDRESS' | translate}}"/>
      <ng-container
        cxDropdownColumn="{{columnDef.CREATED_ON}}"
        [cxOptionalColumn]="true"
        cxDropdownColumnTitle="{{'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.CREATED_ON' | translate}}"/>
      <ng-container
        cxDropdownColumn="{{columnDef.REASON}}"
        [cxOptionalColumn]="true"
        cxDropdownColumnTitle="{{'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.REASON' | translate}}"/>
      <ng-container
        cxDropdownColumn="{{columnDef.CREATED_BY}}"
        [cxOptionalColumn]="true"
        cxDropdownColumnTitle="{{'ORDER_OVERVIEW.ORDER_TABLE_COLUMNS.CREATED_BY' | translate}}"/>

    </ng-container>

    <div>
      <ng-container
        cxTableLabel="{{'ORDER_OVERVIEW.TABLE_LABEL_CUSTOMIZE_COLUMNS' | translate}}"
        [type]="tableLabelType.DROPDOWN_MENU_TITLE"/>
      <ng-container
        cxTableLabel="{{'ORDER_OVERVIEW.TABLE_LABEL_ROWS_PER_PAGE' | translate}}"
        [type]="tableLabelType.PAGINATOR_ITEMS_PER_PAGE_LABEL"/>
      <ng-container
        cxTableLabel="{start} - {end } / { totalRecords } {{'ORDER_OVERVIEW.TABLE_LABEL_ROW' | translate}}"
        [type]="tableLabelType.PAGINATOR_ITEMS_OUT_OF_ALL_LABEL"/>
      <ng-container
        cxTableLabel="{{'ORDER_OVERVIEW.TABLE_LABEL_UNSELECT_ALL' | translate}}"
        [type]="tableLabelType.CHECKBOX_UNSELECT_LABEL"/>
      <ng-container
        cxTableLabel="{{'ORDER_OVERVIEW.TABLE_LABEL_SELECT_ALL' | translate}}"
        [type]="tableLabelType.CHECKBOX_SELECT_LABEL"/>
    </div>

    <cx-table-loading-indicator loadingIndicatorText="Loading..."></cx-table-loading-indicator>
  </cx-table-container>

</div>
